<template>
    <BaseSection
        class="section--usps"
        :container-class="background"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <template
            v-if="description"
            v-slot:description
        >
            {{ description }}
        </template>
        <div class="usps">
            <LabeledIcon
                v-for="(usp, i) in usps"
                :key="i"
                :icon="usp.icon"
                :label="usp.title"
            />
        </div>
    </BaseSection>
</template>

<script>

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        usps() {
            return this.content?.usps ?? [];
        },
        header() {
            return this.content?.header ?? '';
        },
        description() {
            return this.content?.description ?? '';
        },
        background() {
            return this?.content?.background ?? '';
        }
    }
};
</script>

<style lang="less">
    .section.section--usps {
        .section__container {
            max-width: var(--grid-maxWidth-page);
            margin-left: var(--grid-side-gap-xs);
            margin-right: var(--grid-side-gap-xs);
            padding-top: 2.75rem;
            padding-bottom: 2.75rem;
            border-radius: .625rem;

            @media @q-sm-min {
                margin-left: var(--grid-side-gap-sm);
                margin-right: var(--grid-side-gap-sm);
            }

            @media @q-md-min {
                margin-left: var(--grid-side-gap-md);
                margin-right: var(--grid-side-gap-md);
            }

            @media @q-lg-min {
                margin-left: var(--grid-side-gap-lg);
                margin-right: var(--grid-side-gap-lg);
            }

            @media @q-xl-min {
                margin-left: auto;
                margin-right: auto;
                padding-left: 3.3125rem;
                padding-right: 3.3125rem;
            }
        }

        .section__container .section__title {
            .typography-h3();
        }

        .usps {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1.5rem;

            @media @q-md-min {
                gap: 2.75rem;
            }
        }

        .usps .labeled-icon {
            .typography-tag();
            font-size: var(--font-size-sm, .875rem);
        }
    }
</style>
