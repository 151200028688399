<template>
    <div class="labeled-icon">
        <BaseIcon :icon="icon" />
        {{ label }}
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        }
    },
};
</script>

<style lang="less" src="./LabeledIcon.less"></style>
